<!-- 我的任务-我的作业模块 [学生 / 老师] -->
<template>
  <div class="center-situation">
    <div class="task-list wrapper">
      <proTitle></proTitle>
      <el-form :inline="true" :model="formInline" class="form-inline">
        <el-form-item label="任务状态 :">
          <el-select v-model="formInline.status" placeholder="全部" @change="getTaskList()">
            <el-option label="全部" value=""></el-option>
            <el-option label="未开始" value="0"></el-option>
            <el-option label="进行中" value="1"></el-option>
            <el-option label="已结束" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label>
          <el-input v-model="formInline.search" @input="getTaskList()" size="mini" placeholder="输入任务名称"
            suffix-icon="el-icon-search"></el-input>
        </el-form-item>
      </el-form>
      <!-- 列表组件 -->
      <cardList :cardType="'Task'" :dataList="taskList" :total="pages.total" :proid="String(currentProject.id)"
        @returnPagesData="handlePagesData"></cardList>
    </div>
  </div>
</template>

<script>
  import {
    mapState
  } from "vuex"
  export default {
    data() {
      return {
        formInline: { //查询项绑定value
          status: "", //状态查询
          search: "" //名称查询
        },
        taskList: [], //作业列表
        pages: { //分页
          currentPage: 1,
          pageSize: 6,
          total: 0,
        },
      };
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
        currentProject: state => state.user.currentProject,
      })
    },
    watch: {
      'currentProject.id': {
        handler(newVal, oldVal) {
          if (!newVal) return;
          this.pages.currentPage = 1;
          this.getTaskList();
        }
      }
    },
    methods: {
      // 翻页
      handlePagesData(data) {
        // console.log('handlePagesData',data)
        this.pages.currentPage = data.currentPage
        this.pages.pageSize = data.pageSize
        this.getTaskList();
      },
      //获取项目下课程列表
      async getTaskList() {
        if (this.currentProject.id === "0") return;
        let params = {
          page: this.pages.currentPage,
          limit: this.pages.pageSize,
          projectId: this.currentProject.id,
          stuId: this.userInfo.id
        }
        this.formInline.status ? params.status = this.formInline.status : '';
        this.formInline.search ? params.search = this.formInline.search : '';
        let resData = await this.$Api.Form.getTaskList(params)
        console.log('数据---',resData)
        if (JSON.stringify(resData.data.list) == '[]' || !resData.data) {
          this.taskList = [];
          return;
        }
        // this.taskList = resData.data.list;
        this.taskList = resData.data.list.filter(item=>{
          return item.id !== 1010
        });
        this.pages.total = resData.data.totalCount;
      },
    },
    mounted() {
      this.getTaskList();
    },
  };
</script>

<style lang="less" scoped>
  @import "./less/course.less";
</style>